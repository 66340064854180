import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        window.sumFormatterNoRounding = this.sumFormatterNoRounding;
        window.sumFormatterRounding = this.sumFormatterRounding;
        window.dateSorter = this.dateSorter;
        window.dateTimeSorter = this.dateTimeSorter;
        window.numberSorter = this.numberSorter;
        window.currencySorter = this.currencySorter;
        window.percentageSorter = this.percentageSorter;
        window.customNumberSorter = this.customNumberSorter;

        this.loadBootstrapTableLibraries()
            .then(() => {
                if (typeof window.initializeCustomBootstrapTable === 'function') {
                    window.initializeCustomBootstrapTable();
                }
                this.initializeTables();
            })
            .catch((error) => {
                console.error("Error loading scripts:", error);
            });
    }

    loadBootstrapTableLibraries() {
        // Load Bootstrap Table and its extensions
        return this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap-table@1.22.6/dist/bootstrap-table.min.js')
            .then(() => this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap-table@1.22.6/dist/bootstrap-table-locale-all.min.js'))
            .then(() => this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-table/1.22.6/extensions/filter-control/bootstrap-table-filter-control.min.js'));
    }

    loadScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }

    initializeTables() {
        const tables = document.querySelectorAll("table[data-toggle='table']");
        tables.forEach((table) => {
            if (!table.classList.contains("bootstrap-table-initialized")) {
                $(table).bootstrapTable();
                table.classList.add("bootstrap-table-initialized");
            }
        });
    }

    sumFormatterNoRounding(data) {
        var field = this.field;
        var total = data.reduce(function (sum, row) {
            var value = row[field].toString().replace(/\s+/g, '').replace(',', '.');
            return sum + (parseFloat(value) || 0);
        }, 0);

        // Kontrola, zda má výsledek více než 5 desetinných míst
        if (total.toString().includes('.')) {
            var decimalPlaces = total.toString().split('.')[1].length;
            if (decimalPlaces > 5) {
                total = parseFloat(total.toFixed(5));
            }
        }

        // Vrátí výsledek s mezerami mezi tisíci
        return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    sumFormatterRounding(data) {
        var field = this.field;
        var total = data.reduce(function (sum, row) {
            var value = row[field].toString().replace(/\s+/g, '').replace(',', '.');
            return sum + (parseFloat(value) || 0);
        }, 0);

        // Zaokrouhlit výsledek na celá čísla
        total = Math.round(total);

        // Vrátí výsledek s mezerami mezi tisíci
        return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    dateSorter(a, b) {
        function parseDate(dateString) {
            var parts = dateString.split('. ');
            var day = parts[0];
            var month = parts[1];
            var year = parts[2];
            return new Date(`${year}-${month}-${day}`);
        }

        var dateA = parseDate(a);
        var dateB = parseDate(b);

        return dateA - dateB;
    }

    dateTimeSorter(a, b) {
        function parseDateTime(dateTimeString) {
            var [datePart, timePart] = dateTimeString.split(', ');
            var [day, month, year] = datePart.split('. ');
            var [hours, minutes] = timePart.split(':');

            return new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
        }

        var dateTimeA = parseDateTime(a);
        var dateTimeB = parseDateTime(b);

        return dateTimeA - dateTimeB;
    }

    currencySorter(a, b) {
        const numA = parseFloat($(a).data('value'));
        const numB = parseFloat($(b).data('value'));
        console.log("currencySorter called:", a);
        console.log("currencySorter called with:", a, b);
        console.log("currencySorter called with:", numA, numB);


        return numA - numB;
    }

    customNumberSorter(a, b) {
        const cleanedA = a.replace(/\s+/g, '');
        const cleanedB = b.replace(/\s+/g, '');

        const valueA = parseFloat(cleanedA.match(/-?\d+/)[0]);
        const valueB = parseFloat(cleanedB.match(/-?\d+/)[0]);

        return valueA - valueB;
    }

    numberSorter(a, b) {
        return this.cleanNumber(a) - this.cleanNumber(b);
    }

    cleanNumber(value) {
        return parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.'));
    }

    percentageSorter(a, b) {
        const getPercentage = (cell) => {
            const match = cell.match(/-?\d+(\.\d+)?%/);
            return match ? parseFloat(match[0]) : 0;
        };

        const percentageA = getPercentage(a);
        const percentageB = getPercentage(b);

        return percentageA - percentageB;
    }
}
