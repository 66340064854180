import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["modal"]
  static submitting = false

  connect() {
    this.modal = new bootstrap.Modal(this.modalTarget, {
      backdrop: 'static', // Prevent closing by clicking outside
      keyboard: false // Prevent closing with keyboard
    });

    document.addEventListener('turbo:before-fetch-request', this.showModal.bind(this));
    document.addEventListener('turbo:before-fetch-response', this.hideModal.bind(this));
    document.addEventListener('turbo:submit-end', this.hideModal.bind(this));
    document.addEventListener('turbo:frame-render', this.hideModal.bind(this));
    document.addEventListener('turbo:load', this.hideModal.bind(this));
    document.addEventListener('custom:turbo-stream-received', this.hideModal.bind(this));
    document.addEventListener('turbo:render', this.hideModal.bind(this)); // Add this line
    document.addEventListener('turbo:frame-load', this.hideModal.bind(this)); // Add this line
  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-request', this.showModal.bind(this));
    document.removeEventListener('turbo:before-fetch-response', this.hideModal.bind(this));
    document.removeEventListener('turbo:submit-end', this.hideModal.bind(this));
    document.removeEventListener('turbo:frame-render', this.hideModal.bind(this));
    document.removeEventListener('turbo:load', this.hideModal.bind(this));
    document.removeEventListener('custom:turbo-stream-received', this.hideModal.bind(this));
    document.removeEventListener('turbo:render', this.hideModal.bind(this)); // Add this line
    document.removeEventListener('turbo:frame-load', this.hideModal.bind(this)); // Add this line
  }

  showModal(event) {
    const { fetchOptions } = event.detail;
    const method = fetchOptions.method.toUpperCase();
    if (['POST', 'PUT', 'DELETE'].includes(method)) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.closeAllModals();
        this.show();
      }, 1250); // Delay before showing the modal
    }
  }

  hideModal() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.hide();
    this.constructor.submitting = false; // Reset submitting state after response
  }

  submitForm(event) {
    if (this.constructor.submitting) {
      event.preventDefault();
    } else {
      this.constructor.submitting = true;
    }
  }

  show() {
    const openModals = document.querySelectorAll('.modal.show');
    if (openModals.length > 0) {
      return;
    }
    if (!this.modal._isShown) {
      this.modal.show();
    }
  }

  hide() {
    if (this.modal._isShown) {
      this.modal.hide();
    }
  }

  closeAllModals() {
    // Close all Bootstrap modals
    const modals = document.querySelectorAll('.modal.show');
    modals.forEach(modalEl => {
      const modalInstance = bootstrap.Modal.getInstance(modalEl);
      if (modalInstance) {
        modalInstance.hide();
      }
    });

    // Ensure closing of modals inside Turbo Frames
    const turboFrames = document.querySelectorAll('turbo-frame');
    turboFrames.forEach(frame => {
      const innerModals = frame.querySelectorAll('.modal.show');
      innerModals.forEach(innerModal => {
        const modalInstance = bootstrap.Modal.getInstance(innerModal);
        if (modalInstance) {
          modalInstance.hide();
        }
      });
    });
  }
}