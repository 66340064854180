import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["list", "template", "recalculateField"]

    addPair(event) {
        event.preventDefault()
        // Nahradíme NEW_RECORD jedinečným identifikátorem
        const uniqueId = new Date().getTime()
        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, uniqueId)
        this.listTarget.insertAdjacentHTML("beforeend", content)
    }

    removePair(event) {
        event.preventDefault()
        const item = event.target.closest(".pair-item")
        if (item.dataset.newRecord === "true") {
            item.remove()
        } else {
            item.querySelector("input[name*='_destroy']").value = 1
            item.style.display = "none"
        }
    }

    setRecalculate(event) {
        this.recalculateFieldTarget.value = "true"
    }
}
